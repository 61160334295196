.about-section {
  display: flex;
  align-items: center;
  padding: 15px;
  margin-top: 15%;
}

.about-content {
  display: flex;
  align-items: center;
  width: 100%;
  animation: fadeIn 2s ease-in-out;
}

.about-image img {
  border-radius: 50%;
  width: 200px; /* Adjust image size as needed */
  height: 200px;
  object-fit: cover;
  margin-right: 20px;
}

.about-text {
  flex: 1;
  text-align: left;
}

.about-text h2 {
  margin: 0;
  padding-bottom: 10px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.about-text p {
  line-height: 1.6; /* Improve readability with line spacing */
  margin-bottom: 10px;
}

/* Add responsiveness for smaller screens */
@media (max-width: 768px) {
  .about-content {
    flex-direction: column; /* Stack image and text on top of each other */
  }
}

/* Optional: Style the button (contact-button class) */
.contact-button {
  background-color: #3498db; /* Example blue color */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px; /* Optional spacing from content */
  text-decoration: none; /* Remove underline */
}

.contact-button:hover {
  background-color: #3498db; /* Darken on hover */
}
