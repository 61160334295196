.skills-section {
  padding: 20px;
}

.skills-section h2 {
  text-align: center;
  margin-bottom: 30px;
  font-size: 2rem;
  color: #333;
}

.skill {
  margin-bottom: 20px;
}

.skill h4 {
  margin-bottom: 10px;
  font-size: 1.25rem;
  color: #555;
}

.progress {
  background-color: #f1f1f1;
  border-radius: 20px;
  overflow: hidden;
  height: 25px;
}

.progress-bar {
  background-color: #007bff;
  height: 100%;
  line-height: 25px;
  font-size: 14px;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  transition: width 1s ease-in-out;
}

.top-skills {
  margin-bottom: 40px;
  text-align: center;
}

.top-skills h3 {
  margin-bottom: 20px;
  font-size: 1.5rem;
  color: #333;
}

.top-skills ul {
  list-style-type: none;
  padding: 0;
}

.top-skills ul li {
  display: inline-block;
  background-color: #007bff;
  color: #fff;
  border-radius: 20px;
  padding: 10px 20px;
  margin: 5px;
  font-size: 1rem;
  transition: background-color 0.3s ease-in-out;
}

.top-skills ul li:hover {
  background-color: #0056b3;
}
